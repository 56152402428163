/* Template Name: HighVocab - Saas & Software Bootstrap 5 Landing & Admin Dashboard Template
   Author: Themesbrand
   E-mail: support@Themesbrand.in
   Created: August 2019
   Version: 4.2.0
   File Description: Main Css file of the template
*/

//Fonts
@import 'custom/fonts/fonts';

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/mixins.scss';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/topbar';
@import 'custom/structure/footer';

// Components
@import 'custom/components/avatar';
@import 'custom/components/accordion';
@import 'custom/components/preloader';
@import 'custom/components/forms';
// @import "custom/components/breadcrumb";
// @import "custom/components/buttons";
// @import "custom/components/badge";
// @import "custom/components/backgrounds";
// @import "custom/components/alerts";
// @import "custom/components/dropdown";
// @import "custom/components/blockquote";
// @import "custom/components/shadow";
// @import "custom/components/carousel";
// @import "custom/components/reboot";
// @import "custom/components/nav";
// @import "custom/components/text";
// @import "custom/components/progress";
// Pages
@import 'custom/pages/blog-job';
@import 'custom/pages/contact';
@import 'custom/pages/countdown';
@import 'custom/pages/cta';
@import 'custom/pages/features';
@import 'custom/pages/home';
@import 'custom/pages/price';
@import 'custom/pages/team';
@import 'custom/pages/testi';
@import 'custom/pages/user';
@import 'custom/pages/work';
@import 'custom/pages/helper';
@import 'custom/pages/switcher';

iframe {
  width: 0 !important;
  height: 0 !important;
}

.StripeElement {
  iframe {
    width: auto !important;
    height: 40px !important;
  }
}

.modal-video-movie-wrap iframe {
  width: 100% !important;
  height: 100% !important; 
}

.StripeElement--invalid {
  border-color: #f00303;
  border-radius: 4px;
  color: #f00303;
}

.form-stripe {
  margin: 15px auto;
  padding: 0 10px;
  font-size: 16px;
  background-color: #fafafa !important;
  border: 1px solid #bdbdbd;
  height: 40px;
  min-height: 40px;
  width: 320px;
}
